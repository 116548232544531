// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-erbjudanden-js": () => import("./../../../src/pages/erbjudanden.js" /* webpackChunkName: "component---src-pages-erbjudanden-js" */),
  "component---src-pages-evenemang-js": () => import("./../../../src/pages/evenemang.js" /* webpackChunkName: "component---src-pages-evenemang-js" */),
  "component---src-pages-guider-js": () => import("./../../../src/pages/guider.js" /* webpackChunkName: "component---src-pages-guider-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-js": () => import("./../../../src/pages/information.js" /* webpackChunkName: "component---src-pages-information-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-natverk-js": () => import("./../../../src/pages/natverk.js" /* webpackChunkName: "component---src-pages-natverk-js" */),
  "component---src-pages-skickat-js": () => import("./../../../src/pages/skickat.js" /* webpackChunkName: "component---src-pages-skickat-js" */),
  "component---src-templates-article-single-js": () => import("./../../../src/templates/articleSingle.js" /* webpackChunkName: "component---src-templates-article-single-js" */)
}

